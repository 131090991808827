import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className='md:px-12 px-4 pt-12'>
            <div className='bg-[#272727] p-6 md:p-12 rounded-3xl'>
                <div className='xl:px-[70px]'>
                    <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12'>
                        <div>
                            <img src="/main_site/Assets/Icons/logoFooter.svg" alt="logo" className='max-w-full mb-3' />
                            <p className='text-white'>ZaroPay users are responsible for storing their own recovery phrase. If the recovery phrase is lost, the user might not be able to retrieve their private keys</p>
                        </div>
                        <div>
                            <p className='uppercase text-white text-sm'>MENU</p>
                            <div className='mt-6'>
                                <a className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2' href='#hero'>Home</a>
                                <a className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2' href='#features'>Features</a>
                                <a className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2' href='#about'>About ZaroPay</a>
                                <a className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2' href='#supportedCoins'>Supported Coins</a>
                                <a className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2' href='#faqs'>FAQs</a>
                            </div>
                        </div>
                        <div>
                            <p className='uppercase text-white text-sm'>Our Policies</p>
                            <div className='mt-6'>
                                <Link to={'/terms'} className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2'>Terms & Condition</Link>
                                <Link to={'/privacy'} className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer block mt-2'>Privacy Policy</Link>
                            </div>
                        </div>
                        <div>
                            <p className='uppercase text-white text-sm'>Follow us on</p>
                            <div className='mt-6'>
                                <a target='_blank' href='https://www.facebook.com/zaropay' className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer mt-2 flex items-center'><img src="/main_site/Assets/Icons/facebook.svg" className='me-2' alt="" />Facebook</a>
                                <a target='_blank' href='https://linkedin.com/company/zaropay' className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer mt-2 flex items-center'><img src="/main_site/Assets/Icons/linkedin.svg" className='me-2' alt="" />Linkedin</a>
                                <a target='_blank' href='https://www.youtube.com/channel/zaropay' className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer mt-2 flex items-center'><img src="/main_site/Assets/Icons/youtube.svg" className='me-2' alt="" />Youtube</a>
                                <a target='_blank' href='https://www.instagram.com/zaropay_/' className='text-[#cfcfcf] hover:text-white duration-300 cursor-pointer mt-2 flex items-center'><img src="/main_site/Assets/Icons/instagram.svg" className='me-2' alt="" />Instagram</a>
                            </div>
                        </div>
                    </div>
                    <p className='text-[#878787] text-sm text-center border-t border-[#ffffff12] pt-6'>© 2024, All Rights Reserved</p>
                    <p className='text-[#878787] text-sm text-center pt-6'>ZaroPay is a software platform ONLY and does not conduct any independent diligence on or substantive review of any blockchain asset, digital currency, cryptocurrency or associated funds. You are fully and solely responsible for evaluating your investments, for determining whether you will swap blockchain assets based on your own, and for all your decisions as to whether to swap blockchain assets with the Exodus in app swap feature. In many cases, blockchain assets you swap on the basis of your research may not increase in value, and may decrease in value. Similarly, blockchain assets you swap on the basis of your research may increase in value after your swap.</p>
                </div>
            </div>
        </div>
    )
}
